import React from "react";
import { Link } from "react-router-dom";
import aboutImage from "../assets/images/about5.jpg";

import CountUp from "react-countup";

export default function About() {
  return (
    <section className="relative md:py-24 py-16" id="about">
      <div className="container relative">
        <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-6">
          <div className="md:col-span-6">
            <div className="lg:me-8">
              <div className="relative">
                <img
                  src={aboutImage}
                  className="rounded-full shadow dark:shadow-gray-700"
                  alt=""
                />

                <div className="absolute top-1/2 -translate-y-1/2 start-0 end-0 mx-auto size-56 flex justify-center items-center bg-white dark:bg-slate-900 rounded-full shadow dark:shadow-gray-700">
                  <div className="text-center">
                    <span className="font-semibold block mt-2">
                      Industry Research
                      <br />
                      Knowledge Expertise
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="md:col-span-6">
            <div className="lg:ms-8">
              <h6 className="text-teal-500 text-sm font-semibold uppercase mb-2">
                Who Are We ?
              </h6>
              <h3 className="font-semibold text-2xl leading-normal mb-4">
                We're your growth partners
              </h3>

              <p className="text-slate-400 max-w-xl mb-6">
                We drive significant business growth through specialized digital
                campaigns designed to attract high-value projects. Our role is
                to connect your services with the
                best leads, ensuring each campaign turns curiosity into
                contracts, boosting your business growth.
              </p>

              {/* <Link to="" className="h-10 px-6 tracking-wide inline-flex items-center justify-center font-medium rounded-md bg-teal-500 text-white">Read More <i className="mdi mdi-chevron-right align-middle ms-0.5"></i></Link> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
