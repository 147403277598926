import './App.css';
import './assets/css/tailwind.css'
import './assets/css/materialdesignicons.min.css'
import { Route, Routes } from 'react-router-dom';
import IndexThree from './pages/index-three';


function App() {
  return (
    <Routes>
      <Route path='/' element={<IndexThree/>}/>
    </Routes>
  );
}

export default App;
