import React,{useState} from "react";
import { Link } from "react-router-dom";

import heroImg from "../assets/images/about4.jpg"
import bg from '../assets/images/bg/2.png'

import Navbar from "../components/navbar";
import About from "../components/about";
import Services from "../components/services";
import AgencyTab from "../components/agencyTab";
import Cta from "../components/cta";
import Client from "../components/client";
import Pricing from "../components/pricing";
import Blogs from "../components/blog";
import GetInTouch from "../components/getInTuoch";
import Footer from "../components/footer";
import Switcher from "../components/switcher";

import { FiBox } from '../assets/icons/vander'

import CountUp from 'react-countup';

import ModalVideo from 'react-modal-video';
import '../../node_modules/react-modal-video/scss/modal-video.scss';
import { Link as Link1} from "react-scroll";

export default function IndexThree(){
    let [isOpen, setOpen] = useState(false);
    return(
        <>
        <Navbar/>
        <section className="relative flex items-center md:h-screen py-36 bg-no-repeat bg-center bg-cover" id="home" style={{backgroundImage:`url(${bg})`}}>
            <div className="container relative">
                <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-6 gap-6 relative">
                    <div className="lg:col-span-7 md:me-6">
                        <h4 className="font-semibold lg:leading-normal leading-normal tracking-wide text-4xl lg:text-5xl mb-5">Drive More Projects to Your <span className="text-teal-500 font-bold">Business</span></h4>
                        <p className="text-slate-400 text-lg max-w-xl"> Using targeted digital strategies, we attract a steady flow of new clients eager for your expertise, increasing your job opportunities and boosting your revenue.</p>
                    
                        <div className="relative mt-6 space-x-1">
                            <Link1 to="contact" smooth={true} duration={1000} activeClass='active' spy={true} className="h-10 px-6 tracking-wide cursor-pointer select-none inline-flex items-center justify-center font-medium rounded-md bg-teal-500 text-white">Apply Now</Link1>
                        </div>
                    </div>

                    <div className="lg:col-span-5">
                        <div className="relative">
                            <img src={heroImg} className="mx-auto rounded-[150px] rounded-br-2xl shadow dark:shadow-gray-700 w-[90%]" alt=""/>
    
                            <div className="overflow-hidden after:content-[''] after:absolute after:h-10 after:w-10 after:bg-teal-500/20 after:top-0 after:start-0 after:-z-1 after:rounded-lg after:animate-[spin_10s_linear_infinite]"></div>
                        
                            <div className="absolute flex justify-between items-center bottom-[-82px] md:-start-10 -start-5 p-4 rounded-lg shadow-md dark:shadow-gray-800 bg-white dark:bg-slate-900 w-60 m-3">
                                <div className="flex items-center">
                                    <div className="flex items-center justify-center h-[65px] min-w-[65px] bg-teal-500/5 text-teal-500 text-center rounded-full me-3">
                                        <FiBox className="h-6 w-6"/>
                                    </div>
                                    <div className="flex-1">
                                        <span className="text-slate-400">Projects</span>
                                        <p className="text-xl font-bold"><CountUp className="counter-value" start={2} end={8} prefix="+"/></p>
                                    </div>
                                </div>
    
                                <span className="text-green-600"><i className="uil uil-chart-down"></i> 162%</span>
                            </div>
    
                            <div className="absolute top-[-84px] md:-end-10 -end-5 p-4 rounded-lg shadow-md dark:shadow-gray-800 bg-white dark:bg-slate-900 w-48 m-3">
                                <h5 className="text-lg font-semibold">Revenue</h5>
                                <div className="flex justify-between mb-2">
                                    <span className="text-slate-400">Increase</span>
                                    <span className="text-slate-400">84%</span>
                                </div>
                                <div className="w-full bg-gray-100 dark:bg-gray-800 rounded-full h-[6px]">
                                    <div className="bg-teal-500 h-[6px] rounded-full" style={{width: "84%"}}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <About/>
        <Services/>
        <AgencyTab/>
        {/* <Cta/> */}
        {/* <Client/> */}
        {/* <Pricing/> */}
        {/* <Blogs/> */}
        <GetInTouch/>
        <Footer/>
        {/* <Switcher/> */}
        </>
    )
}