import { FiTrendingUp, FiDollarSign, FiCompass,FiCommand, FiBox, FiCamera, FiBell, FiMonitor } from '../assets/icons/vander'
import client1 from '../assets/images/team/01.jpg'
import client2 from '../assets/images/team/02.jpg'
import client3 from '../assets/images/team/03.jpg'
import client4 from '../assets/images/team/04.jpg'
import client5 from '../assets/images/team/05.jpg'
import client6 from '../assets/images/team/06.jpg'

import blog1 from '../assets/images/blog/01.jpg'
import blog2 from '../assets/images/blog/02.jpg'
import blog3 from '../assets/images/blog/03.jpg'

import company1 from '../assets/images/team/amazon.svg'
import company2 from '../assets/images/team/google.svg'
import company3 from '../assets/images/team/lenovo.svg'
import company4 from '../assets/images/team/paypal.svg'
import company5 from '../assets/images/team/shopify.svg'
import company6 from '../assets/images/team/spotify.svg'

export const servicesData = [
    {
        icon: FiTrendingUp,
        title: 'Grow Your Business',
        desc: 'Elevate your business with tailored digital ad campaigns designed to maximize exposure and increase project inquiries.'
    },
    {
        icon: FiDollarSign,
        title: 'Drive More Sales',
        desc: 'Use the power of precision-targeted digital ads to directly boost your sales by connecting you with high-intent clients ready to start their projects.'
    },
    {
        icon: FiCompass,
        title: 'Handled By Experts',
        desc: 'Our digital marketing expertize utilizes cutting-edge strategies to ensure your ads reach and engage the right audience.'
    },
    {
        icon: FiMonitor,
        title: 'Qualified Lead Generation',
        desc: 'Our campaigns are designed to deliver quality leads, ensuring you receive inquiries from potential clients ready to engage and invest in your services.'
    },
]


export const clientData = [
    {
        image:client1,
        name:'Calvin Carlo',
        title:'Manager',
        desc:'" It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. "'
    },
    {
        image:client2,
        name:'Christa Smith',
        title:'Manager',
        desc:'" The most well-known dummy text is the Lorem Ipsum, which is said to have originated in the 16th century. "'
    },
    {
        image:client3,
        name:'Jemina CLone',
        title:'Manager',
        desc:'" One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others"'
    },
    {
        image:client4,
        name:'Smith Vodka',
        title:'Manager',
        desc:'" Thus, Lorem Ipsum has only limited suitability as a visual filler for German texts "'
    },
    {
        image:client5,
        name:'Cristino Murfi',
        title:'Manager',
        desc:'" There is now an abundance of readable dummy texts. These are usually used when a text is required"'
    },
    {
        image:client6,
        name:'Cristino Murfi',
        title:'Manager',
        desc:'" According to most sources, Lorum Ipsum can be traced back to a text composed by Cicero "'
    },
]

export const blogData = [
    {
        image:blog1,
        title:'Design your apps in your own way',
        desc:'This is required when, for example, the final text is not yet available.',
        date:'13th March 2023'
    },
    {
        image:blog2,
        title:'How apps is changing the IT world',
        desc:'This is required when, for example, the final text is not yet available.',
        date:'5th May 2023'
    },
    {
        image:blog3,
        title:'Smartest Applications for Business',
        desc:'This is required when, for example, the final text is not yet available.',
        date:'19th June 2023'
    },
]

export const companyLogo = [company1, company2, company3, company4, company5, company6]